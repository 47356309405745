export const environment = {
	production: false,
	account: '001i000001tzCcu',
	accountKia: '001i000001tzCcu',
	accountBella: '001i000001tzCcu',
	accountHonda: '001i000001tzCcu',
	accountAcura: '001i000001tzCcu',
	accountMazda: '001i000001tzCcu',
	accountNissan: '001i000001tzCcu',
	account399: '001i000001tzCcu',
	accountHyundai: '001i000001tzCcu',

	salesApiLink: 'https://helios-sales-api-stg.apps-connectassistance.com',
	//salesApiLink: 'http://localhost:3000',
	apiLink: 'https://helios-api-stg.apps-connectassistance.com',
	// apiLink: 'http://localhost:3000',
	athMovilLink: '/#/ath_movil_staging/?id=',
	stripeKey:
		'pk_test_5190uUtHDk9bOOMjA7dns0vu7sAXkleiJBKscW9qOt7TdSlwMepnZsk3CtJHva6MKs4wxraMboSucd9yzBoh0aaP9003SXU0vHf',
	stripeKeyPR:
		'pk_test_5190uUtHDk9bOOMjA7dns0vu7sAXkleiJBKscW9qOt7TdSlwMepnZsk3CtJHva6MKs4wxraMboSucd9yzBoh0aaP9003SXU0vHf',
	stripeKeyPA: 'pk_test_80VLmgp51XNdQID9pO1qFq6a',
	stripeKeyCR:
		'pk_test_51Gv9rELGDymA3BfKBjBynowDjKtzIPWq4utKtNnMEkGdJrvkHnckLTIzZFvIbCoVTyvyOIrDSw5bbcEcFcJz7GfP0000yRpvK3',
	stripeKeyCO:
		'pk_test_51CwGaHA0iubLCAyqNCojfDXNctsEls26kkoX6dy1fJnP4T3eH1Dz8TwBS0jzoUTGkykOdptc8gnLtKr6TlKVJ3mM00o1fXHvVs',
	accountDefault: 'Dev Test Account',
	stripeDashboard: 'https://dashboard.stripe.com/test/customers/',
	athMovilSandbox: true,
	merakiCmsUrl: {
		base: 'https://meraki-cms-stg.apps-connectassistance.com/api/',
		apiToken:
			'Bearer 4b1c3ef35241b7ab37a255eeb4eccc0a357c7f5625ee0f25cb149304b3438d45cb985b010871c2a430436000c56aa36f34037d6b0cd35db9674ce1cabb770b6eac53993879affe86193d12c87bf413dbb33170bce37369b62631933e60ec07ab2f234bb1f9ca7b92d423c54b0f7008434935b8aea8d79708b6bd38dcd65e7c28',
	},
};
