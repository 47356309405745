export const environment = {
	production: true,
	account: '0011Y00002Mtuxw',
	accountKia: '001i000001Ar9h9',
	accountBella: '001i000002CBhli',
	accountHonda: '001i000002CBh8b',
	accountAcura: '001i000002CBh9k',
	accountMazda: '0011Y00002Fnf0X',
	accountNissan: '0011Y00002KQPfn',
	account399: '001i000001JXFFo',
	accountHyundai: '0014z00001dKb4T',
	apiLink: 'https://helios-api.apps-connectassistance.com',
	salesApiLink: 'https://helios-sales-api.apps-connectassistance.com',
	athMovilLink: 'https://sales.cra.pr/ath_movil/?id=',
	stripeKey:
		'pk_live_5190uUtHDk9bOOMjAOpZVAFtuwQV1BwulCFhIJNFtLddpDFwZ7bXmnxtlmtUuoLCZ2Wxu8pJ18Xn30dFFaJc6sD7P00lukF5fmN',
	stripeKeyPR:
		'pk_live_5190uUtHDk9bOOMjAOpZVAFtuwQV1BwulCFhIJNFtLddpDFwZ7bXmnxtlmtUuoLCZ2Wxu8pJ18Xn30dFFaJc6sD7P00lukF5fmN',
	stripeKeyPA:
		'pk_live_51CwGaHA0iubLCAyqcT8HerUcFmOJURSg3IT12Yf2WXiAXQslx7FCcieN3V6lrDJegTlccxXq7BXGi3MjMxtiddGl00xdYdJ5h7',
	stripeKeyCR:
		'pk_live_51CwGaHA0iubLCAyqcT8HerUcFmOJURSg3IT12Yf2WXiAXQslx7FCcieN3V6lrDJegTlccxXq7BXGi3MjMxtiddGl00xdYdJ5h7',
	stripeKeyCO:
		'pk_live_51CwGaHA0iubLCAyqcT8HerUcFmOJURSg3IT12Yf2WXiAXQslx7FCcieN3V6lrDJegTlccxXq7BXGi3MjMxtiddGl00xdYdJ5h7',
	accountDefault: 'Connect Assistance',
	stripeDashboard: 'https://dashboard.stripe.com/customers/',
	athMovilSandbox: false,
	merakiCmsUrl: {
		base: 'https://meraki-cms.apps-connectassistance.com/api/',
		apiToken:
			'Bearer 0e0c40352edb32cc530417072f4426d746887741f0a970713507973e5908c546ad4db5b11edab73f93f119313be5edb136bf277fe28f779158c04f6020bb09bc41e8152eccf4a2dfabffb3e27a79b062720f86f5694b98a9191192823684fcb56e93585079fa6780c2c2f3551988c31aed2d691f9d98b310965674568260eeec',
	},
};
