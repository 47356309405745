import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { ResponseData } from './../models/ResponseData';
import { AuthenticationService } from './auth.service';

const url = environment.apiLink;
const salesUrl = environment.salesApiLink;
const merakiUrl = environment.merakiCmsUrl.base;

@Injectable()
export class HttpService {
	constructor(private http: HttpClient, private auth: AuthenticationService) {}

	private getRequestOptions() {
		let headers = new HttpHeaders();
		const token = this.auth.getAuthToken() ? this.auth.getAuthToken() : 'Token';
		headers = headers.set('Content-Type', 'application/json').set('Authorization', token);
		return { headers: headers };
	}

	private get(URL): Observable<any> {
		return this.http.get(URL, this.getRequestOptions());
	}
	private post(URL, data): Observable<any> {
		return this.http.post(URL, data, this.getRequestOptions());
	}
	private put(URL, data): Observable<any> {
		return this.http.put(URL, data, this.getRequestOptions());
	}
	private patch(URL, data): Observable<any> {
		return this.http.patch(URL, data, this.getRequestOptions());
	}
	// Login
	login(loginData): Observable<any> {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		const options = { headers: headers };
		return Observable.create(observer => {
			this.http.post<any>(url + '/utils/login', loginData, options).subscribe(response => {
				const responseData = response;
				if (responseData.status) {
					this.auth.storeAuth(responseData.token.token, responseData.token.expires, responseData.userData);
					observer.next(responseData);
					observer.complete();
				} else {
					observer.next(responseData);
					observer.complete();
				}
			});
		});
	}

	getMake(year: String) {
		return this.get(salesUrl + '/utils/getMake/' + year).map(response => response.data);
	}

	getModel(year: String, make: String) {
		return this.get(salesUrl + '/utils/getModel/' + year + '/' + make).map(response => response.data);
	}

	getCity(zipCode: String): Observable<any> {
		return this.http.get('https://api.zippopotam.us/PR/' + zipCode).map(response => response);
	}

	getProvinces(countryCode: String): Observable<any> {
		return this.http
			.get('https://connectservicesapp-quality.azurewebsites.net/api/v1/catalogs/provinces?code=' + countryCode)
			.map(response => response);
	}

	getDepartments(countryCode: String): Observable<any> {
		return this.http
			.get(merakiUrl + 'country/departments/' + countryCode, {
				headers: {
					Authorization: environment.merakiCmsUrl.apiToken,
				},
			})
			.map(response => response);
	}

	getMunicipalities(countryCode: string, departmentId: String): Observable<any> {
		return this.http
			.get(merakiUrl + 'country/municipality/' + countryCode + '/' + departmentId, {
				headers: {
					Authorization: environment.merakiCmsUrl.apiToken,
				},
			})
			.map(response => response);
	}

	getInspectionCenters() {
		return this.get(salesUrl + '/sales/inspectionCenters').map(response => response.data);
	}

	// Create Subscription
	cSubscription(data) {
		return this.post(salesUrl + '/sales/cCustomerWithPlan', data).map(response => response.data);
	}

	// Add Susbscription to an existing Stripe Customer
	createSubscriptionToExistingStripeCustomer(data) {
		return this.post(salesUrl + '/sales/createStripeSubscription', data).map(response => response.data);
	}

	/* Summary , Sales, Subscription */

	// SALES

	// Create Stripe Token
	createStripeToken(data) {
		return this.post(salesUrl + '/sales/createStripeToken', data).map(response => response.data);
	}

	// Create Stripe Customer With Plan
	campaignsOfTwoOrThreeYears(data) {
		return this.post(salesUrl + '/sales/campaignsOfTwoOrThreeYears', data).map(response => response.data);
	}

	// Create Stripe Customer With Plan
	cStripeCustomerWithPlan(data) {
		return this.post(salesUrl + '/sales/cCustomerWithPlan', data).map(response => response.data);
	}

	// Create Subscription
	cSubscriptionMongo(data) {
		return this.post(salesUrl + '/sales/saveSubscription', data).map(response => response.data);
	}

	addFilesSubscription(data: FormData) {
		return this.http
			.post(salesUrl + '/sales/addVaccinationCard', data)
			.subscribe(response => console.log(response), error => console.log(error));
	}

	// Create Subscription
	generateShortLinkPayment(data) {
		return this.post(salesUrl + '/sales/addPresale', data).map(response => response.data);
	}

	// Get the client Id from salesforce and then save subscription created Log
	gClientIdFromSf(data) {
		const mongoSubId = data.mongoSubId;
		return this.post(salesUrl + '/sales/gClientIdFromSf', data).map(response => response.data);
	}

	// verify plate
	verifyPlate(data): Observable<any> {
		return this.post(salesUrl + '/sales/verifyPlate', data).map(response => response.data);
	}

	// SUBSCRIPTIONS

	// get Payments by Client Id
	getPaymentsByClientId(id) {
		return this.get(salesUrl + '/subscriptions/gPaymentsByClientId/' + id).map(response => response.data);
	}

	// get All Subscriptions
	getSubscriptions(status: String) {
		return this.get(salesUrl + '/subscriptions/gSubscriptions').map(response => response.data);
	}

	// Update Subscription Date with Proration
	eSubscriptionDate(data) {
		return this.post(salesUrl + '/subscriptions/eSubscriptionDate', data).map(response => response.data);
	}

	// Update Subscription Date with Proration
	downloadFile(data) {
		return this.post(salesUrl + '/subscriptions/downloadFile', data).map(response => response.data);
	}

	// buscar los últimos 4 de la tarjeta en Stripe
	getCreditCardLastFour(customerIdStripe, data) {
		return this.post(salesUrl + '/subscriptions/getCreditCardLastFour/' + customerIdStripe + '', data).map(
			response => response.data
		);
	}

	getSubscriptionInvoices(data) {
		return this.post(salesUrl + '/subscriptions/subscriptionInvoices', data).map(response => response.data);
	}

	// Update Subscription - Client Info
	updateSubscriptionClientInfo(data) {
		return this.post(salesUrl + '/subscriptions/eSubscriptionClientInfo', data).map(response => response.data);
	}

	// Get Subscription Logs by Mongo Id
	getSubscriptionLogs(mongoSubId: String) {
		return this.get(salesUrl + '/subscriptions/getSubscriptionLogs/' + mongoSubId).map(response => response.data);
	}

	// Cancel Subscription
	cancelSubscription(data) {
		return this.post(salesUrl + '/subscriptions/cancelSubscription', data).map(response => response.data);
	}

	// Update Subscription - Items
	updateSubscriptionItems(data) {
		return this.post(salesUrl + '/subscriptions/eSubscriptionItems', data).map(response => response);
	}

	// Update to Delete a Subscription Auto, Moto, or Home
	chargeOrRefundEndoso(data) {
		return this.post(salesUrl + '/subscriptions/chargeOrRefundEndoso', data).map(response => response.data);
	}

	// Update to Delete a Subscription Auto, Moto, or Home
	deleteSubscriptionVehicleOrHome(data) {
		return this.post(salesUrl + '/subscriptions/dSubscriptionVehicleOrHome', data).map(response => response.data);
	}

	// add a Vehicle or Home to Subscription
	addSubscriptionVehicleOrHome(data) {
		return this.post(salesUrl + '/subscriptions/addSubscriptionVehicleOrHome', data).map(response => response.data);
	}

	// Update Credit Card
	updateCard(data) {
		return this.post(salesUrl + '/subscriptions/eCreditCard', data).map(response => response.data);
	}

	// Update Credit Card
	addPayment(data) {
		return this.post(salesUrl + '/subscriptions/addPayment', data).map(response => response.data);
	}

	// Reactivate Subscription
	reactivateSubscription(data) {
		return this.post(salesUrl + '/subscriptions/reactivateSubscription', data).map(response => response);
	}

	// Add Notes Subscriptions
	addNotesSubscriptions(data) {
		return this.post(salesUrl + '/subscriptions/addNotesSubscriptions', data).map(response => response.data);
	}

	// Create Cash Payment
	createCashPayment(data) {
		return this.post(salesUrl + '/subscriptions/createCashPayment', data).map(response => response.data);
	}

	// Switch Cash Payment to Ath Movil Payment
	switchCashToAth(data) {
		return this.post(salesUrl + '/subscriptions/switchCashToAth', data).map(response => response.data);
	}

	// Get Subscription Notes
	getNotesSubscriptions(id) {
		return this.get(salesUrl + '/subscriptions/getNotesSubscriptions/' + id).map(response => response.data);
	}

	// Update in Mongo Subscription items after reactivation
	updateSubscriptionAfterReactivation(data) {
		return this.post(salesUrl + '/subscriptions/updateSubscriptionAfterReactivation', data).map(
			response => response.data
		);
	}

	// Get Subscrioptions
	public getSubscriptionsAll(body): Promise<ResponseData> {
		return this.post(`${salesUrl}/subscriptions/all`, body).toPromise();
	}

	// Search in Subscription
	public searchSubscriptions(body): Promise<ResponseData> {
		return this.post(`${salesUrl}/subscriptions/search`, body).toPromise();
	}

	public async upgradeSubscription(data: any): Promise<ResponseData> {
		return this.patch(`${salesUrl}/subscriptions/upgrade`, data).toPromise();
	}

	public async getCoupon(data) {
		return this.post(`${salesUrl}/sales/gCupon`, data).toPromise();
	}

	// Add Notes Subscriptions
	public updateSubscriptionAdmin(data) {
		return this.post(salesUrl + '/subscriptions/updateSubscriptionAdm', data).map(response => response.data);
	}

	public removeDiscount(data) {
		console.log('TRY TO REMOVE DISCOUNT');
		return this.post(salesUrl + '/subscriptions/removeSubscriptionCoupon', data).map(response => response.data);
	}

	/**
	 * Funcion para obtener los datos de los precios desde sales api
	 * @param data
	 * @returns
	 */
	async getPrices(data: any) {
		const pricesData = this.post(salesUrl + '/sales/getPrices', data)
			.map(response => response.data)
			.toPromise();
		return pricesData;
	}

	/**
	 * Funcion para obtener los datos de los precios desde sales api
	 * @param data
	 * @returns
	 */
	public getPlans(data: any) {
		const pricesData = this.post(salesUrl + '/sales/getPlans', data).map(response => response.data);

		return pricesData;
	}

	/**
	 * Funcion para obtener los datos de los precios desde sales api
	 * @param data
	 * @returns
	 */
	public getCountry(data: any) {
		if (!data.country) {
			return;
		}
		const pricesData = this.post(salesUrl + '/sales/getCountry', data).map(response => response.data);

		return pricesData;
	}
}
